import React from "react";

const BlogList = (props) => {
  return (
    <div className="content">
      <section className="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="text">
                <div className="section-title text-left">
                  <span className="sub-title">RECHTLICHE INFORMATIONEN</span>
                  <h2>Blog</h2>
                </div>
                <p>TODO: loop through all blog entries</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogList;
