import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import Content from "./pages/content";
import Imprint from "./pages/imprint";
import DataProtection from "./pages/data-protection";
import BlogList from "./pages/blog-list";
import BlogDetail from "./pages/blog-detail";
import NotFound from "./pages/not-found";
import history from "./history";

export default class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Content}></Route>
          <Route path="/imprint" component={Imprint}></Route>
          <Route path="/data-protection" component={DataProtection}></Route>
          <Route path="/blog" exact component={BlogList}></Route>
          <Route path="/blog/:blogid" exact component={BlogDetail}></Route>
          <Route component={NotFound}></Route>
        </Switch>
      </Router>
    );
  }
}
