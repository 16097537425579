import React from "react";

const Blog = (props) => {
  return (
    <section className="blog" id="blog">
      <div className="container">
        <div className="section-title text-center">
          <span className="sub-title">BLOG</span>
          <h2>Unser hilfreicher Blog</h2>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="blog-box">
              <div className="image">
                <img
                  src="images/Rectangle 7197.svg"
                  alt=""
                  className="img-fluid"
                ></img>
              </div>
              <div className="text">
                <div className="date">
                  <span>25. Mai, 2020</span>
                </div>
                <div className="name">
                  <span>zochi.cc stellt sich vor</span>
                </div>
                <p>
                  Wir sind ein aufstrebendes Unternehmen für maßgeschneiderte
                  Softwarelösungen. Im Mai 2020 wurde das Unternehmen zochi.cc
                  von zwei ....
                </p>
                <a href="/blog/wir-ueber-uns">Read More</a>
              </div>
            </div>
          </div>
          {/*<div className="col-sm-12 col-md-4 col-lg-4">
            <div className="blog-box">
              <div className="image">
                <img
                  src="images/Rectangle 7198.svg"
                  alt=""
                  className="img-fluid"
                ></img>
              </div>
              <div className="text">
                <div className="date">
                  <span>February 13, 2021</span>
                </div>
                <div className="name">
                  <span>Lorem Ipsum</span>
                </div>
                <p>
                  Lorem Ipsum is simply dum my text of the printing and type
                  setting industry....
                </p>
                <a href="/#TODO">Read More</a>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="blog-box">
              <div className="image">
                <img
                  src="images/Rectangle 7199.svg"
                  alt=""
                  className="img-fluid"
                ></img>
              </div>
              <div className="text">
                <div className="date">
                  <span>February 13, 2021</span>
                </div>
                <div className="name">
                  <span>Lorem Ipsum</span>
                </div>
                <p>
                  Lorem Ipsum is simply dum my text of the printing and type
                  setting industry....
                </p>
                <a href="/#TODO">Read More</a>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="all-blog text-center">
          <a href="/#TODO" className="btn btn-succes">
            See Full Blog
          </a>
        </div> */}
      </div>
    </section>
  );
};

export default Blog;
