import React from "react";
import axios from "axios";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formContactFullName: "",
      formContactEMail: "",
      formContactMsg: "",
      showAlert: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const config = {
      headers: {
        "API-KEY": process.env.REACT_APP_API_KEY,
      },
      params: {
        from: this.state.formContactEMail,
        subject: "Kon­takt­auf­nah­me",
        fullname: this.state.formContactFullName,
        email: "info@zochi.cc",
        msg: this.state.formContactMsg,
      },
    };

    await axios.get(process.env.REACT_APP_API_URL, config).then(() => {
      this.setState({
        formContactFullName: "",
        formContactEMail: "",
        formContactMsg: "",
        showAlert: true,
      });
    });
  };

  render() {
    return (
      <section className="contact-us" id="contact">
        <div className="container">
          <div className="section-title text-center">
            <span className="sub-title">Kontakt</span>
            <h2>Kontakt aufnehmen</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="map">
                <img
                  src="images/anfahrt-zochi-cc.svg"
                  alt=""
                  className="img-fluid"
                ></img>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div
                style={this.state.showAlert ? {} : { display: "none" }}
                className="alert alert-success"
                role="alert"
              >
                Ihre Anfrage wurde gesendet. Vielen Dank
              </div>
              <div className="title">
                <span>Nehmen Sie Kontakt zu uns auf</span>
              </div>
              <div className="form">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Vor- und Nachname"
                      name="formContactFullName"
                      value={this.state.formContactFullName}
                      onChange={this.handleChange}
                    ></input>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="E-Mail Adresse"
                      name="formContactEMail"
                      value={this.state.formContactEMail}
                      onChange={this.handleChange}
                    ></input>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Ihre Nachricht"
                      name="formContactMsg"
                      value={this.state.formContactMsg}
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                  <div className="submit">
                    <button type="submit" className="btn btn-succes">
                      senden
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactUs;
