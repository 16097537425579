import React from "react";

const FeatureAndService = (props) => {
  return (
    <section className="feature" id="feature">
      <div className="container">
        <div className="section-title text-center">
          <span className="sub-title">UNSERE LEISTUNGEN</span>
          <h2>Features & Services</h2>
        </div>
        <div className="feature-bg">
          <div className="row">
            <div className="col-md-4 col-lg-4">
              <div className="box-provide">
                <div className="image">
                  <img
                    src="images/idea 1.svg"
                    alt=""
                    className="img-fluid"
                  ></img>
                </div>
                <div className="title">Konzeption & Planung</div>
                <p>
                  Ein gut durchdachtes und umfangreiches Konzept garantiert den
                  langfristigen Erfolg einer neuen Systemlösung. Gemeinsam
                  entwickeln wir die bestmögliche Lösung für Sie. Unser Fokus
                  liegt dabei auf Ihren Bedürfnissen für eine bestmögliche
                  Umsetzung.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="box-provide">
                <div className="image">
                  <img
                    src="images/Group 30540.svg"
                    alt=""
                    className="img-fluid"
                  ></img>
                </div>
                <div className="title">Entwicklung</div>
                <p>
                  Wir sind Experten für kundenspezifische Softwarelösungen.
                  Außerdem bieten wir komplette WordPress- und
                  E-Commerce-Lösungen an. Um Ihnen die beste Lösung für Ihren
                  Anwendungsfall zu gewährleisten, werfen wir einen prüfenden
                  Blick auf jedes Detail und nutzen modernste Technologien. Wir
                  sind erst dann zufrieden, wenn Sie es auch sind.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="box-provide">
                <div className="image">
                  <img
                    src="images/clean 1.svg"
                    alt=""
                    className="img-fluid"
                  ></img>
                </div>
                <div className="title">Leistungen</div>
                <p>
                  Unser Angebot umfasst zusätzlich Cloud-basierte und
                  plattformübergreifende Anwendungen, sowie eingehende Beratung
                  und Cloud-Analyse.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureAndService;
