import React from "react";

const Strategy = (props) => {
  return (
    <section className="strategy" id="strategy">
      <div className="container">
        <div className="section-title text-center">
          <span className="sub-title">STRATEGIE</span>
          <h2>Unsere Prozesse</h2>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="branding">
              <div className="title">
                <span>Support</span>
              </div>
              <p>
                Unsere Zusammenarbeit endet nicht mit Ihrer passenden
                Softwarelösung oder Anwendung. Auch die Wartung und der Support
                gehört für uns zu einer perfekten Customer Experience dazu.
                Gerne stehen wir Ihnen jederzeit für Fragen und Hilfestellungen
                zur Verfügung.
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="branding">
              <div className="title">
                <span>Beratung & Planung</span>
              </div>
              <p>
                Am Anfang jeder Lösung steht ein Problem. Wir beleuchten Ihr
                Problem von allen Seiten und beraten Sie so, dass Sie alle
                Schritte der Entwicklung verstehen und mit dem Ergebnis
                zufrieden sind. Das richtige Konzept deckt alle Bereiche Ihres
                Anwendungsfalls ab und soll langfristig Bestand haben.
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="branding">
              <div className="title">
                <span>Entwicklung</span>
              </div>
              <p>
                Von der Anforderungsanalyse bis zur Umsetzung bieten wir
                exzellente Fachkompetenz. Auf Basis Ihrer Vorstellungen und des
                gemeinsam erstellten Konzepts, entwickeln wir eine belastbare
                und anhaltende Softwarelösung, die einfach in der Anwendung ist.
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="branding">
              <div className="title">
                <span>Implementierung</span>
              </div>
              <p>
                Wir nehmen uns Zeit für Ihre Softwarelösung. Das beinhaltet auch
                die Testphase vor dem Go-Live der Software. Definierte Prozesse
                für eine sichere Performance sind für uns selbstverständlich,
                damit Sie von einer fehlerfreien Lösung ausschließlich
                profitieren.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Strategy;
