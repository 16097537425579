import React from "react";

const Imprint = (props) => {
  return (
    <div className="content">
      <section className="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="text">
                <div className="section-title text-left">
                  <span className="sub-title">Oops!</span>
                  <h2>404 - Seite wurde nicht gefunden</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Imprint;
