import ReactDOM from "react-dom";
import Header from "./header/header";
import Footer from "./footer/footer";
import Routes from "./routes";

const App = () => {
  return (
    <>
      <Header></Header>
      <Routes></Routes>
      <Footer></Footer>
    </>
  );
};

ReactDOM.render(<App />, document.querySelector("#body"));
