import React from "react";

const Imprint = (props) => {
  return (
    <div className="content">
      <section className="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="text">
                <div className="section-title text-left">
                  <span className="sub-title">RECHTLICHE INFORMATIONEN</span>
                  <h2>Impressum</h2>
                </div>
                <p>
                  zochi.cc GmbH
                  <br />
                  Lägernstrasse 15
                  <br />
                  5610 Wohlen
                  <br />
                  Schweiz
                  <br />
                </p>
                <p className="p6">
                  Mail: info[at]zochi.cc
                  <br />
                  Telefon: +41 56 561 67 16
                  <br />
                  Verantwortliche Person: Nick Zocher (Geschäftsführer)
                  <br />
                  Firmennummer: CHE-206.598.873 (gem. Handelsregister)
                </p>
                <span className="sub-title-black paddingTop25">
                  Haftungsausschluss
                </span>
                <p className="p6">
                  zochi.cc GmbH übernimmt keinerlei Gewähr hinsichtlich der
                  inhaltlichen Richtigkeit, Genauigkeit, Aktualität,
                  Zuverlässigkeit und Vollständigkeit der Informationen.
                  Haftungsansprüche wegen Schäden materieller oder immaterieller
                  Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung
                  der veröffentlichten Informationen, durch Missbrauch der
                  Verbindung oder durch technische Störungen entstanden sind,
                  werden ausgeschlossen. Alle Angebote sind unverbindlich.
                  zochi.cc GmbH behält es sich ausdrücklich vor, Teile der
                  Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
                  verändern, zu ergänzen, zu löschen oder die Veröffentlichung
                  zeitweise oder endgültig einzustellen.
                </p>
                <span className="sub-title-black paddingTop25">
                  Haftung für Links
                </span>
                <p className="p6">
                  Verweise und Links auf Webseiten Dritter liegen ausserhalb
                  unseres Verantwortungsbereichs. Es wird jegliche Verantwortung
                  für solche Webseiten abgelehnt. Der Zugriff und die Nutzung
                  solcher Webseiten erfolgen auf eigene Gefahr des Nutzers oder
                  der Nutzerin.
                </p>
                <span className="sub-title-black paddingTop25">
                  Urheberrechte
                </span>
                <p className="p6">
                  Die Urheber- und alle anderen Rechte an Inhalten, Bildern,
                  Fotos oder anderen Dateien auf der Website gehören
                  ausschliesslich zochi.cc GmbH oder den speziell genannten
                  Rechtsinhabern. Für die Reproduktion jeglicher Elemente ist
                  die schriftliche Zustimmung der Urheberrechtsträger im Voraus
                  einzuholen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Imprint;
