import React from "react";

const Header = (props) => {
  return (
    <header className="header">
      <div className="container">
        <nav className="navbar navbar-expand-md navbar-light ">
          <a className="navbar-brand mr-0" href="/">
            <img
              src="/images/zochi-cc-logo.png"
              alt="zochi.cc logo"
              className="img-fluid"
            ></img>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <span className="toggle-bar"></span>
              <span className="toggle-bar"></span>
              <span className="toggle-bar"></span>
            </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a className="nav-link" href="/">
                  Home<span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#references">
                  Referenzen
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#blog">
                  Blog
                </a>
              </li>
            </ul>
            <a className="btn btn-success" href="/#contact">
              Kontakt
            </a>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
