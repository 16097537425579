import React from "react";

const WirUeberUns = (props) => {
  return (
    <div className="content">
      <section className="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="text">
                <div className="section-title text-left">
                  <span className="sub-title">BLOG</span>
                  <h2>zochi.cc stellt sich vor</h2>
                </div>
                <p className="p6">
                  Wir sind ein aufstrebendes Unternehmen für maßgeschneiderte
                  Softwarelösungen. Im Mai 2020 wurde das Unternehmen zochi.cc
                  von zwei Programmierern und Software-Enthusiasten mit der
                  Mission gegründet, die Arbeitswelt für andere einfacher zu
                  machen. Dabei sind wir immer auf der Suche nach neuen
                  Herausforderungen und bereit, neue Wege zu gehen. Unsere
                  maßgeschneiderten Softwarelösungen zeugen von technologischem
                  Know-how und innovativer Kreativität.
                </p>
                <span className="sub-title-black paddingTop25">
                  Softwarelösungen mit Blick über den Tellerrand
                </span>
                <p className="p6">
                  Unser Firmensitz befindet sich im schönen Wohlen im Schweizer
                  Aargau. Von hier aus beraten und unterstützen wir unsere
                  Kunden auch über die Landesgrenze hinaus, denn IT ist unsere
                  Leidenschaft. Wir schätzen das kreative und touristische
                  Umfeld unseres Standortes in Wohlen. Die richtigen
                  Softwarelösungen sind ein entscheidender Faktor für
                  erfolgreiche Geschäftsprozesse. Unser Ansporn ist die Freude
                  an unserem Beruf und die Bereitschaft zu helfen. Wir möchten
                  die tägliche Arbeit und die Prozesse unserer Kunden nach ihren
                  Vorstellungen gestalten.
                </p>
                <p className="p6">
                  Wir haben zochi.cc mit dem Ziel gegründet, anspruchsvolle,
                  individuelle und benutzerfreundliche Software für komplexe
                  Geschäftsanforderungen zu entwickeln. Neben Komplettlösungen
                  für WordPress und E-Commerce ist genau das unsere Spezialität.
                  Heute verfügen wir über die modernsten Technologien, um
                  intelligente Softwarelösungen mit maximalem Kundennutzen zu
                  realisieren.
                </p>
                <span className="sub-title-black paddingTop25">
                  Die Digitalisierung ermöglicht Unternehmen Zukunftsvisionen zu
                  realisieren
                </span>
                <p className="p6">
                  Als passionierte Programmierer weisen wir mehr als 20 Jahre
                  Erfahrung in der Entwicklung, Architektur und Wartung von
                  Software auf. Uns ist bewusst, dass sich die
                  Entwicklungsgeschwindigkeit auf allen technologischen Ebenen
                  rasant entwickelt und sich die Anforderungen an Betriebe
                  andauernd ändern. Gleichzeitig nehmen aber auch die
                  Bedürfnisse der Kunden ständig zu. Daraus ergeben sich
                  komplexe Aufgabenstellungen, die mit Standardsoftware selten
                  hinreichend gelöst werden können. Darum entwickeln wir
                  Software individuell für den jeweiligen Anwendungsfall. Damit
                  ermöglichen wir von zochi.cc Unternehmen, alle Potentiale für
                  den strategischen Vorteil zu nutzen, die sich aus diesen neuen
                  Möglichkeiten von State of the Art-Technologien ergeben. Das
                  ist es, was unseren Beruf als individuelle Softwareentwickler
                  ausmacht.
                </p>
                <span className="sub-title-black paddingTop25">
                  Ihre Softwarelösung als Basis Ihres Erfolgs
                </span>
                <p className="p6">
                  Mit der Entwicklung der bestmöglichen Softwarelösung
                  übernehmen wir eine verantwortungsvolle Aufgabe für die
                  Zukunft unserer Kunden und sind dankbar für das Vertrauen, das
                  in unsere Hände gelegt wird. Mit unserer Arbeit setzen wir
                  Ideen und Visionen in die Tat um, um Unternehmen nachhaltig an
                  seine Ziele zu bringen. Mit der richtigen Software geben wir
                  Führungskräften ein Tool an die Hand, das der Schlüssel zu
                  weiteren Erfolgen in der Zukunft ist. Denn darin sehen wir den
                  Kern, um den technologischen Wandel zu meistern und den Weg
                  innerhalb der eigenen Branche aktiv mitzugestalten und
                  anzuführen.
                </p>
                <span className="sub-title-black paddingTop25">
                  Wir von zochi.cc sind:
                </span>
                <ul>
                  <li>Ein Team aus Experten und Visionären </li>
                  <li>
                    Erfahren im Bereich der Programmierung und gleichzeitig ein
                    aufstrebendes Unternehmen
                  </li>
                  <li>Passionierte Software-Enthusiasten </li>
                  <li>
                    Uns der verantwortungsvollen Aufgabe bewusst, die
                    Softwareentwicklung mit sich bring
                  </li>
                  <li>
                    Der Überzeugung mit unseren Lösungen einen nachhaltigen
                    Mehrwert für unsere Kunden zu schaffen
                  </li>
                  <li>
                    Offen für neue Trends und Technologien und arbeiten immer am
                    Zahn der Zeit
                  </li>
                  <li>
                    Immer auf der Suche nach der besten Lösung für die
                    individuellen Anforderungen unserer Kunden
                  </li>
                  <li>
                    Nach der Entwicklung noch lange nicht fertig mit unserer
                    Arbeit und bieten ein Komplettpaket inklusive Wartung und
                    Support an
                  </li>
                  <li>
                    Ein Kompetenter Partner mit Weitsicht für künftige
                    Anforderungen
                  </li>
                  <li>
                    Bereit, jedem Unternehmen neue Chancen für die Zukunft zu
                    eröffnen
                  </li>
                  <li>
                    Mit dem Verständnis für die Branchen unserer Kunden
                    ausgestattet
                  </li>
                </ul>
                <p className="p6">
                  Sie sind auf der Suche nach der passenden Umsetzung für Ihre
                  Ideen und wollen Ihr Unternehmen digitalisieren, dann
                  kontaktieren Sie uns gerne für ein Beratungsgespräch.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WirUeberUns;
