import React from "react";

const AboutUs = (props) => {
  return (
    <section className="about" id="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-5 col-lg-5">
            <div className="image">
              <img
                src="images/Group 30586.svg"
                alt=""
                className="img-fluid"
              ></img>
            </div>
          </div>
          <div className="col-sm-12 col-md-7 col-lg-7">
            <div className="text">
              <div className="section-title text-left">
                <span className="sub-title">ÜBER UNS</span>
                <h2>
                  Wir realisieren Ihre Idee ganz nach SCRUM und mit modernster
                  Technologie
                </h2>
              </div>
              <p class="p6">
                Standardsoftware wird nicht immer den individuellen
                Anforderungen Ihres Unternehmens gerecht. Mit der Beratung durch
                unsere Experten und einer auf Ihre Bedürfnisse zugeschnittenen
                Software öffnen wir gemeinsam neue Wege für Ihre betriebliche
                Zukunft.
              </p>
              <p>
                Wir denken mit und schaffen neue Wege mit digitalen Lösungen.
                Werden Sie zum innovativen Vorreiter Ihrer Branche mit einer
                maßgeschneiderten Softwareentwicklung. Gemeinsam mit uns lösen
                Sie einzigartige geschäftliche Herausforderungen, um
                signifikante Ergebnisse zu erzielen.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
