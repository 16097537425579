import React from "react";

const DataProtection = (props) => {
  return (
    <div className="content">
      <section className="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="text">
                <div className="section-title text-left">
                  <span className="sub-title">RECHTLICHE INFORMATIONEN</span>
                  <h2>Datenschutzerklärung</h2>
                </div>
                <p className="p6">
                  <strong>zochi.cc GmbH</strong>, Lägernstrasse 15, 5610 Wohlen
                  (CHE-206.598.873), vertreten durch Nick Zocher und Gino
                  Ziörjen, ist Betreiberin der Website zochi.cc und somit
                  verantwortlich für die Erhebung, Verarbeitung und Nutzung
                  Ihrer persönlichen Daten und die Vereinbarkeit der
                  Datenbearbeitung mit dem geltenden Recht.
                </p>
                <p className="p6">
                  Ihr Vertrauen ist uns wichtig. Wir nehmen das Thema
                  Datenschutz ernst und achten auf entsprechende Sicherheit.
                  Selbstverständlich beachten wir die gesetzlichen Bestimmungen
                  des Bundesgesetzes über den Datenschutz (DSG), der Verordnung
                  zum Bundesgesetz über den Datenschutz (VDSG), des
                  Fernmeldegesetztes (FMG) und, soweit anwendbar, andere
                  datenschutzrechtliche Bestimmungen, insbesondere die
                  Datenschutzgrundverordnung der Europäischen Union (DSGVO).
                </p>
                <p className="p6">
                  Damit Sie wissen, welche personenbezogenen Daten wir von Ihnen
                  erheben und für welche Zwecke wir sie verwenden, nehmen Sie
                  bitte die nachstehenden Informationen zur Kenntnis.
                </p>
                <span className="sub-title-black paddingTop25">
                  1) Grundsätze der Datenverarbeitung auf zochi.cc
                </span>
                <p className="p6">
                  Die auf zochi.cc erhobenen personenbezogenen Daten
                  <ol type="a">
                    <li>
                      werden auf rechtmässige Weise, nach Treu und Glauben und
                      in einer für die betroffene Person nachvollziehbaren Weise
                      verarbeitet.
                    </li>
                    <li>
                      werden einzig für die Durchführung und Abwicklung der auf
                      der Website angebotenen Dienstleistungen und Angebote
                      verwendet und werden unter keinen Umständen in für mit
                      diesem Zweck nicht zu vereinbarende Weise
                      weiterverarbeitet.
                    </li>
                    <li>
                      welche im Hinblick auf die Zwecke ihrer Verarbeitung
                      unrichtig sind, werden unverzüglich gelöscht oder
                      berichtigt.
                    </li>
                    <li>
                      werden nur so lange gespeichert, wie es für die Zwecke,
                      für die sie verarbeitet werden, erforderlich ist.
                    </li>
                    <li>
                      werden auf eine Weise verarbeitet, die eine angemessene
                      Sicherheit gewährleistet, einschliesslich Schutz vor
                      unbefugter oder unrechtmässiger Verarbeitung und vor
                      unabsichtlichem Verlust, unbeabsichtigte Zerstörung oder
                      unbeabsichtigte Schädigung.
                    </li>
                  </ol>
                </p>
                <span className="sub-title-black paddingTop25">
                  2) Rechtmässigkeit der Datenverarbeitung
                </span>
                <p className="p6">
                  Indem der Nutzer die Website zochi.cc einsieht, gibt der
                  Nutzer seine Einwilligung zur Verarbeitung der ihn
                  betreffenden personenbezogenen Daten.
                </p>
                <p className="p6">
                  Die Angebote auf zochi.cc richten sich ausschliesslich an
                  Personen über 16 Jahre. Erkannte personenbezogene Daten von
                  Kindern unter 16 Jahren werden umgehend gelöscht.
                </p>
                <p className="p6">
                  Die betroffene Person hat das Recht, Ihre Einwilligung
                  jederzeit zu widerrufen (per E-Mail auf info[at]zochi.cc).
                  Durch den Widerruf der Einwilligung wird die Rechtmässigkeit
                  der aufgrund der Einwilligung bis zum Widerruf erfolgten
                  Verarbeitung nicht berührt.
                </p>
                <span className="sub-title-black paddingTop25">
                  3) Umfang und Zweck der Erhebung, Verarbeitung und Nutzung
                  personenbezogener Daten
                </span>
                <p className="p6">
                  <ol type="a">
                    <li>
                      <strong>beim Besuch von zochi.cc</strong>
                      <p className="p6">
                        Beim Besuch von zochi.cc speichern unsere Server
                        temporär jeden Zugriff in einer Protokolldatei. Folgende
                        Daten werden dabei ohne Ihr Zutun erfasst und bis zur
                        automatisierten Löschung nach spätestens zwölf Monaten
                        von uns gespeichert:
                      </p>
                      <ol type="a">
                        <li>die IP-Adresse des anfragenden Rechners</li>
                        <li>das Datum und die Uhrzeit des Zugriffs</li>
                        <li>der Name und die URL der abgerufenen Datei</li>
                        <li>
                          das Betriebssystem Ihres Rechners und der von Ihnen
                          verwendete Browser
                        </li>
                        <li>
                          das Land von welchem sie zugegriffen haben und die
                          Spracheinstellungen bei Ihrem Browser
                        </li>
                        <li>installierte Cookies</li>
                      </ol>
                      <p className="p6">
                        Die Erhebung und Verarbeitung dieser Daten erfolgt zu
                        dem Zweck, die Nutzung unserer Website zu ermöglichen
                        (Verbindungsaufbau), die Systemsicherheit und
                        -stabilität zu gewährleisten und die Optimierung unseres
                        Internetangebots zu ermöglichen sowie zu internen
                        statistischen Zwecken. Die IP-Adresse wird insbesondere
                        dazu verwendet, um das Aufenthaltsland des
                        Website-Besuchers zu erfassen und eine darauf
                        abgestimmte Voreinstellung der Sprache der Website
                        vorzunehmen. Ferner wird die IP-Adresse bei Angriffen
                        auf die Netzinfrastruktur von zochi.cc zu statistischen
                        Zwecken ausgewertet.
                      </p>
                    </li>
                    <li>
                      <strong>bei der Nutzung unseres Kontaktformulars</strong>
                      <p className="p6">
                        Sie haben die Möglichkeit, ein Kontaktformular zu
                        verwenden, um mit uns in Kontakt zu treten. Die Eingabe
                        folgender personenbezogenen Daten ist zwingend:
                      </p>
                      <ol type="a">
                        <li>E-Mail Adresse</li>
                        <li>Vorname und Name</li>
                      </ol>
                      <p className="p6">
                        Wir werden die zwingend erforderlichen Eingaben
                        kennzeichnen: Wenn diese Informationen nicht zur
                        Verfügung gestellt werden, kann dies die Erbringung
                        unserer Dienstleistungen behindern. Die Angabe anderer
                        Informationen ist optional und hat keinen Einfluss auf
                        Nutzung unserer Website.
                      </p>
                      <p className="p6">
                        Wir verwenden diese Daten nur, um Ihre Kontaktanfrage
                        bestmöglich und personalisiert beantworten zu können.
                        Sie können dieser Datenverarbeitung jederzeit
                        widersprechen und verlangen, dass wir Ihre Daten löschen
                        (per E-Mail auf info[at]zochi.cc).
                      </p>
                    </li>
                  </ol>
                </p>
                <span className="sub-title-black paddingTop25">
                  4) Nutzung Ihrer Daten zu Werbezwecken
                </span>
                <p className="p6">
                  <ol type="a">
                    <li>
                      <strong>Im Allgemeinen</strong>
                      <p className="p6">
                        zochi.cc GmbH kann personenbezogene Daten der Nutzer von
                        zochi.cc verwenden, um ihre Dienstleistungen zu bewerben
                        (Online- und/oder Printwerbung etc.). Es ist zochi.cc
                        GmbH erlaubt, zu diesem Zweck personenbezogene Daten an
                        Dritte weiterzugeben. Der Nutzer erklärt sich
                        ausdrücklich mit dieser Verwendung seiner Daten
                        einverstanden.
                      </p>
                    </li>
                    <li>
                      <strong>
                        Erstellen von pseudonomysierten Nutzungsprofilen
                      </strong>
                      <p className="p6">
                        Um Ihnen auf unserer Website personalisierte
                        Dienstleistungen und Informationen zur Verfügung zu
                        stellen (On-Site-Targeting), nutzen und analysieren wir
                        die Daten, welche wir über Sie sammeln, wenn Sie die
                        Website besuchen. Bei der entsprechenden Bearbeitung
                        können gegebenenfalls auch sog. Cookies zum Einsatz
                        kommen. Die Analyse Ihres Nutzerverhaltens kann zur
                        Erstellung eines sogenannten Nutzungsprofiles führen.
                        Eine Zusammenführung der Nutzungsdaten erfolgt lediglich
                        mit pseudonymen, nie jedoch mit nicht-pseudonymisierten
                        personenbezogenen Daten.
                      </p>
                      <p className="p6">
                        Um personalisiertes Marketing in sozialen Netzwerken zu
                        ermöglichen, binden wir auf der Website so genannte
                        Remarketing Pixel von Facebook und Twitter ein. Sofern
                        Sie einen Account bei einem hierüber einbezogenen
                        sozialen Netzwerk besitzen und zum Zeitpunkt des
                        Seitenbesuchs dort angemeldet sind, verknüpft dieses
                        Pixel den Seitenbesuch mit Ihrem Account. Loggen Sie
                        sich vor Seitenbesuch aus ihrem jeweiligen Account aus,
                        um eine Verknüpfung zu unterbinden. Weitere
                        Einstellungen zur Werbung können Sie in den jeweiligen
                        Sozialen Netzwerken in Ihrem Nutzerprofil vornehmen. Die
                        Erstellung von pseudonymisierten Nutzerprofilen zu
                        Werbe- und Analysezwecken stützen wir auf ein
                        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
                        DSGVO. Das berechtigte Interesse besteht im
                        Direktmarketing und der Analyse der Nutzung unserer
                        Website.
                      </p>
                    </li>
                  </ol>
                </p>
                <span className="sub-title-black paddingTop25">
                  5) Einbindung von Diensten und Inhalten Dritter
                </span>
                <p className="p6">
                  Es kann vorkommen, dass innerhalb unseres Onlineangebotes
                  Inhalte oder Dienste von Dritt-Anbietern, wie zum Beispiel
                  Stadtpläne oder Schriftarten von anderen Webseiten eingebunden
                  werden. Die Einbindung von Inhalten der Dritt-Anbieter setzt
                  immer voraus, dass die Dritt-Anbieter die IP-Adresse der
                  Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte
                  nicht an den Browser der Nutzer senden könnten. Die IP-Adresse
                  ist damit für die Darstellung dieser Inhalte erforderlich. Des
                  Weiteren können die Anbieter der Dritt-Inhalte eigene Cookies
                  setzen und die Daten der Nutzer für eigene Zwecke verarbeiten.
                  Dabei können aus den verarbeiteten Daten Nutzungsprofile der
                  Nutzer erstellt werden. Wir werden diese Inhalte möglichst
                  datensparsam und datenvermeidend einsetzen sowie im Hinblick
                  auf die Datensicherheit zuverlässige Dritt-Anbieter wählen.
                </p>
                <p className="p6">
                  Die nachfolgende Darstellung bietet eine Übersicht von
                  Dritt-Anbietern sowie ihrer Inhalte, nebst Links zu deren
                  Datenschutzerklärungen, welche weitere Hinweise zur
                  Verarbeitung von Daten und, z.T. bereits hier genannt,
                  Widerspruchsmöglichkeiten (sog. Opt-Out) enthalten:
                </p>
                <p className="p6">
                  Externe Schriftarten von Google Inc.,{" "}
                  <a
                    href="https://www.google.com/fonts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.google.com/fonts
                  </a>{" "}
                  („Google Fonts“). Die Einbindung der Google Fonts erfolgt
                  durch einen Serveraufruf bei Google (in der Regel in den USA).
                  Datenschutzerklärung:
                  <a
                    href="https://www.google.com/policies/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.google.com/policies/privacy
                  </a>
                  , Opt-Out:
                  <a
                    href="https://www.google.com/settings/ads"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.google.com/settings/ads
                  </a>
                  .
                </p>
                <p className="p6">
                  Landkarten des Dienstes „Google Maps“ des Dritt-Anbieters
                  Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
                  94043, USA, gestellt.Datenschutzerklärung:{" "}
                  <a
                    href="https://www.google.com/policies/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.google.com/policies/privacy
                  </a>
                  , Opt-Out:{" "}
                  <a
                    href="https://www.google.com/settings/ads"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.google.com/settings/ads
                  </a>
                  .
                </p>
                <span className="sub-title-black paddingTop25">
                  6) Weitergabe der Daten an Dritte
                </span>
                <p className="p6">
                  Wir geben Ihre personenbezogenen Daten nur weiter, wenn Sie
                  ausdrücklich eingewilligt haben, hierfür eine gesetzliche
                  Verpflichtung besteht oder dies zur Durchsetzung unserer
                  Rechte, insbesondere zur Durchsetzung von Ansprüchen aus dem
                  Verhältnis zwischen Ihnen und uns, erforderlich ist.
                </p>
                <p className="p6">
                  Darüber hinaus geben wir Ihre Daten an Dritte weiter, soweit
                  dies im Rahmen der Nutzung der Website für die Bereitstellung
                  der von Ihnen gewünschten Dienstleistungen sowie der Analyse
                  Ihres Nutzerverhaltens erforderlich ist. Soweit dies für die
                  in Satz 1 genannten Zwecke erforderlich ist, kann die
                  Weitergabe auch ins Ausland erfolgen. Sofern die Website Links
                  zu Websites Dritter enthält, haben wir nach dem Anklicken
                  dieser Links keinen Einfluss mehr auf die Erhebung,
                  Verarbeitung, Speicherung oder Nutzung personenbezogener Daten
                  durch den Dritten und übernehmen dafür keine Verantwortung.
                </p>
                <span className="sub-title-black paddingTop25">
                  7) Übermittlung personenbezogener Daten ins Ausland
                </span>
                <p className="p6">
                  Wir sind berechtigt, Ihre persönlichen Daten auch an dritte
                  Unternehmen (beauftragte Dienstleister) im Ausland zu
                  übertragen, sofern dies für die in dieser Datenschutzerklärung
                  beschriebenen Datenbearbeitungen erforderlich ist. Diese sind
                  im gleichen Umfang wie wir selbst zum Datenschutz
                  verpflichtet. Wenn das Datenschutzniveau in einem Land nicht
                  dem schweizerischen bzw. dem europäischen entspricht, stellen
                  wir vertraglich sicher, dass der Schutz Ihrer
                  personenbezogenen Daten demjenigen in der Schweiz bzw. in der
                  EU jederzeit entspricht.
                </p>
                <span className="sub-title-black paddingTop25">
                  8) Datensicherheit
                </span>
                <p className="p6">
                  Wir bedienen uns geeigneter technischer und organisatorischer
                  Sicherheitsmassnahmen, um Ihre bei uns gespeicherten
                  persönlichen Daten gegen Manipulation, teilweisen oder
                  vollständigen Verlust und gegen unbefugten Zugriff Dritter zu
                  schützen. Unsere Sicherheitsmassnahmen werden entsprechend der
                  technologischen Entwicklung fortlaufend verbessert.
                </p>
                <p className="p6">
                  Sie sollten Ihre Zahlungsinformationen stets vertraulich
                  behandeln und das Browserfenster schliessen, wenn Sie die
                  Kommunikation mit uns beendet haben, insbesondere, wenn Sie
                  den Computer gemeinsam mit anderen nutzen.
                </p>
                <p className="p6">
                  Auch den unternehmensinternen Datenschutz nehmen wir sehr
                  ernst. Unsere Mitarbeitenden und die von uns beauftragten
                  Dienstleistungsunternehmen sind von uns zur Verschwiegenheit
                  und zur Einhaltung der datenschutzrechtlichen Bestimmungen
                  verpflichtet worden.
                </p>
                <span className="sub-title-black paddingTop25">9) Cookies</span>
                <p className="p6">
                  Cookies helfen unter vielen Aspekten, Ihren Besuch auf unserer
                  Website einfacher, angenehmer und sinnvoller zu gestalten.
                  Cookies sind Informationsdateien, die Ihr Webbrowser
                  automatisch auf der Festplatte Ihres Computers speichert, wenn
                  Sie unsere Website besuchen. Cookies beschädigen weder die
                  Festplatte Ihres Rechners noch werden von diesen Cookies
                  persönliche Daten der Anwender an uns übermittelt.
                </p>
                <p className="p6">
                  Wir setzen Cookies beispielsweise ein, um die Ihnen
                  angezeigten Informationen, Angebote und Werbung besser auf
                  Ihre individuellen Interessen auszurichten. Die Verwendung
                  führt nicht dazu, dass wir neue personenbezogene Daten über
                  Sie als Onlinebesucher erhalten. Die meisten Internet-Browser
                  akzeptieren Cookies automatisch. Sie können Ihren Browser
                  jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer
                  gespeichert werden oder stets ein Hinweis erscheint, wenn Sie
                  ein neues Cookie erhalten.
                </p>
                <p className="p6">
                  Sie haben auf unserer Website auch die Möglichkeit, Cookies zu
                  deaktivieren. Die Deaktivierung von Cookies kann dazu führen,
                  dass Sie nicht alle Funktionen unserer Website nutzen können.
                </p>
                <span className="sub-title-black paddingTop25">
                  10) Recht auf Auskunft, Berichtigung, Löschung und
                  Einschränkung der Verarbeitung; Recht auf Datenübertragbarkeit
                </span>
                <p className="p6">
                  Sie haben das Recht, über die personenbezogenen Daten, die von
                  uns über Sie gespeichert werden, auf Antrag unentgeltlich
                  Auskunft zu erhalten. Zusätzlich haben Sie das Recht auf
                  Berichtigung unrichtiger Daten und das Recht auf Löschung
                  Ihrer personenbezogenen Daten, soweit dem keine gesetzliche
                  Aufbewahrungspflicht oder ein Erlaubnistatbestand, der uns die
                  Verarbeitung der Daten gestattet, entgegensteht. Sie haben
                  gemäss den Artikeln 18 und 21 DSGVO zudem das Recht, eine
                  Einschränkung der Datenverarbeitung zu verlangen sowie der
                  Datenverarbeitung zu widersprechen.
                </p>
                <p className="p6">
                  Sie haben zudem das Recht, diejenigen Daten, die Sie uns
                  übergeben haben, wieder von uns herauszuverlangen. Auf Anfrage
                  geben wir die Daten auch an einen Dritten Ihrer Wahl weiter.
                  Sie haben das Recht, die Daten in einem gängigen Dateiformat
                  zu erhalten.
                </p>
                <p className="p6">
                  Sie können uns für die vorgenannten Zwecke über die
                  E-Mail-Adresse info[at]zochi.cc erreichen. Für die Bearbeitung
                  Ihrer Gesuche können wir, nach eigenem Ermessen, einen
                  Identitätsnachweis verlangen.
                </p>
                <p className="p6">
                  Sie können uns auch mitteilen, was mit Ihren Daten nach Ihrem
                  Tod geschehen soll, indem Sie uns entsprechende Anweisungen
                  geben.
                </p>
                <span className="sub-title-black paddingTop25">
                  11) Aufbewahrung von Daten
                </span>
                <p className="p6">
                  Wir speichern personenbezogene Daten nur so lange, wie es
                  erforderlich ist, um die oben genannten Analysedienste im
                  Rahmen unseres berechtigten Interesses zu verwenden, um in dem
                  oben genannten Umfang Dienstleistungen auszuführen, die Sie
                  gewünscht oder zu denen Sie Ihre Einwilligung erteilt haben
                  sowie um unseren gesetzlichen Verpflichtungen nachzukommen.
                </p>
                <span className="sub-title-black paddingTop25">
                  12) Recht auf Beschwerde bei einer Datenschutzaufsichtsbehörde
                </span>
                <p className="p6">
                  Falls Sie Ihren Wohnsitz in einem EU Staat haben, steht Ihnen
                  das Recht zu, sich jederzeit bei einer
                  Datenschutzaufsichtsbehörde zu beschweren.
                </p>
                <br />
                <br />
                <p>Wohlen, 15. September 2020</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DataProtection;
