import React from "react";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import WirUeberUns from "./blog-details/wir-ueber-uns";

const BlogDetail = (props) => {
  let params = useParams();

  switch (params.blogid) {
    case "wir-ueber-uns":
      return <WirUeberUns />;
    default:
      return <Redirect to="/" />;
  }
};

export default BlogDetail;
