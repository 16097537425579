import React from "react";

const MainBanner = (props) => {
  return (
    <section className="main-banner">
      <div className="bannertext-image">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="text">
                <div className="banner-title">
                  <span className="sub-title">Guten Tag</span>
                  <h1>Wir setzen Ihre Ideen & Vision in die Tat um</h1>
                </div>
                <p>
                  Mit Innovation, Agilität und Leidenschaft schaffen wir
                  maßgeschneiderte Softwarelösungen für die Ansprüche Ihres
                  Unternehmens. Als Software Experten begleiten wir den gesamten
                  Life Cycle der Programmentwicklung. Unser Leistungsangebot
                  reicht von der Beratung, über die Konzeption und
                  Implementierung bis hin zur Wartung und zum Support Ihrer
                  spezifischen Softwarelösung. Unseren Fokus legen wir auf Ihre
                  Bedürfnisse, auf individuelle Lösungsansätze, Visibilität und
                  auf eine intuitive Anwendung.
                </p>
                <a href="#feature" className="btn btn-succes">
                  Erkunden
                </a>
                <a href="#about" className="btn btn-Primary">
                  mehr erfahren
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="image">
                <img
                  src="images/main-banner-group.svg"
                  alt="Grafik einer Gruppe"
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainBanner;
