import React from "react";
import MainBanner from "./sections/main-banner";
import FeatureAndService from "./sections/feature-and-service";
import AboutUs from "./sections/about-us";
import Strategy from "./sections/strategy";
import Testimonial from "./sections/testimonial";
import Project from "./sections/project";
import Blog from "./sections/blog";
import ContactUs from "./sections/contact-us";

const Content = (props) => {
  return (
    <div className="content">
      <MainBanner></MainBanner>
      <FeatureAndService></FeatureAndService>
      <AboutUs></AboutUs>
      <Strategy></Strategy>
      {/* <Testimonial></Testimonial> */}
      <Project></Project>
      <Blog></Blog>
      <ContactUs></ContactUs>
    </div>
  );
};

export default Content;
