import React from "react";

const Project = (props) => {
  return (
    <section className="project" id="references">
      <div className="container">
        <div className="section-title text-center">
          <span className="sub-title">PORTFOLIO</span>
          <h2>Unsere Projekte</h2>
        </div>
        <div className="text-image-1">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-5 col-lg-5">
              <div className="image">
                <img
                  src="images/eloquenzio-preview.svg"
                  alt=""
                  className="img-fluid"
                ></img>
              </div>
            </div>
            <div className="col-sm-12 col-md-7 col-lg-7">
              <div className="section-title text-left">
                <h2>eloquenz.io</h2>
              </div>
              <p>
                Erweitern und trainieren Sie Ihren Wortschatz mit dieser App!
                <br />
                Geeignet für alle, die ihre Sprachfertigkeiten auf ein höheres
                Niveau bringen wollen, egal ob Sie Anfänger, Rentner oder
                studierter Germanist sind. Mit dem Wortschatz-Trainer erwerben
                Sie gehobenes Deutsch in Sprache und Schrift – mit jeder Menge
                Spaß beim Lernen!
              </p>
              {/*<a href="/#TODO" className="check-link">
                Check Case Study
              </a>*/}
            </div>
          </div>
        </div>
        {/*
        <div className="text-image-2">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-7 col-lg-7">
              <div className="section-title text-left">
                <h2>Project name</h2>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eros
                porta et consectetur auctor gravida mauris tempus pellentesque.
                Nulla ornare potenti in eu in commodo magna amet. Eros porta et
                consectetur auctor gravida mauris tempus pellentesque. Nulla
                ornare potenti in eu in commodo magna amet.{" "}
              </p>
              <a href="#" className="check-link">
                Check Case Study
              </a>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-5">
              <div className="image">
                <img
                  src="images/Rectangle 7260.svg"
                  alt=""
                  className="img-fluid"
                ></img>
              </div>
            </div>
          </div>
        </div>
        */}
        {/*<div className="all-project text-center">
          <a href="/#TODO" className="btn btn-succes">
            See All Projects
          </a>
        </div> */}
      </div>
    </section>
  );
};

export default Project;
